<template>
  <section>
    <div class="ssp-configuration">
      <Teleport to="#header-buttons">
        <div class="checkbox">
          <span class="font-weight-bold pr-1">HiveStack</span>
          <b-form-checkbox
            v-model="device.is_hivestack_ad_show"
            class="custom-control-primary check-text"
            size="sm"
            inline
            switch
          />
        </div>
      </Teleport>
      <div class="mt-2">
        <!--Hive Stack Section-->
        <div class="hivestack">
          <b-card no-body>
            <validation-observer ref="hivestackForm">
              <b-row>
                <b-col
                  lg="12"
                  xl="12"
                  md="12"
                >
                  <b-card-body title="Network">
                    <b-row>
                      <b-col
                        lg="6"
                        md="6"
                        xl="6"
                      >
                        <b-form-group
                          label="Network ID"
                          label-for="network-id"
                        >
                          <b-form-input
                            id="network-id"
                            v-model="device.network_id"
                            variant="primary"
                            placeholder="Enter Network ID"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="6"
                        md="6"
                        xl="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Hivestack CPM"
                          vid="hivestack-cpm"
                          rules="required|numeric|min_value:5"
                        >
                          <b-form-group
                            label="Floor CPM ($)"
                            label-for="floor-cpm"
                          >
                            <b-form-input
                              id="floor-cpm"
                              v-model="device.floor_cpm"
                              placeholder="$ 100"
                              variant="primary"
                              type="number"
                              :state="errors.length > 0 ? false:null"
                              vid="hivestack-cpm"
                              @wheel.prevent
                              @touchmove.prevent
                              @scroll.prevent
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>
                <!--Sites Section-->
                <b-col
                  lg="12"
                  xl="12"
                  md="12"
                >
                  <b-card-body
                    title="Sites"
                    class="pt-0"
                  >
                    <b-row>
                      <b-col
                        lg="6"
                        md="6"
                        xl="6"
                      >
                        <b-form-group
                          label-for="site-name"
                          label="Site Name"
                        >
                          <b-form-input
                            id="site-name"
                            v-model="device.site.name"
                            placeholder="Enter Site Name"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="6"
                        xl="6"
                        md="6"
                      >
                        <b-form-group
                          label-for="device-externalid"
                          label="Site External Id"
                        >
                          <b-form-input
                            id="device-externalid"
                            v-model="device.site.external_id"
                            placeholder="Enter External ID"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="12"
                        md="12"
                        xl="12"
                        class="mt-2"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Site Description"
                          vid="device-site-description"
                          rules="required"
                        >
                          <b-form-group
                            label-for="device-site-description"
                            label="Site Description"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-textarea
                              id="device-site-description"
                              v-model="device.site.description"
                              rows="0"
                              vid="device-site-description"
                              placeholder="Enter site Description"
                              :state="errors.length > 0 ? false:null"
                              variant="primary"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>
                <!--Unit Section-->
                <b-col
                  lg="12"
                  xl="12"
                  md="12"
                >
                  <b-card-body
                    title="Unit/Device"
                    class="pt-0"
                  >
                    <b-row>
                      <b-col
                        lg="6"
                        xl="6"
                        md="6"
                      >
                        <b-form-group
                          label-for="site-unit-name"
                          label="Unit Name"
                        >
                          <b-form-input
                            id="site-unit-name"
                            v-model="device.name"
                            placeholder="Enter Unit Name"
                            variant="primary"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="6"
                        xl="6"
                        md="6"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Media Type"
                          vid="media-type"
                          rules="required"
                        >
                          <b-form-group
                            label-for="media-type"
                            label="Select Media Type"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <v-select
                              v-model="device.mediatype_id"
                              input-id="media-type"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Select Mediatype"
                              vid="media-type"
                              :options="device.media_types"
                              label="name"
                              :reduce="media_type => media_type.mediatype_id"
                            />
                          </b-form-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-col>
                      <b-col
                        lg="12"
                        xl="12"
                        md="12"
                        class="mt-1"
                      >
                        <ValidationProvider
                          v-slot="{errors}"
                          name="Unit Description"
                          vid="site-unit-description"
                          rules="required"
                        >
                          <b-form-group
                            label-for="site-unit-description"
                            label="Unit Description"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-textarea
                              id="site-unit-description"
                              v-model="device.description"
                              vid="site-unit-description"
                              rows="1"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Enter Unit Description"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col
                        lg="12"
                        xl="12"
                        md="12"
                        class="mt-1"
                      >
                        <h5 class="font-weight-bold">
                          Dimensions
                        </h5>
                      </b-col>
                      <b-col
                        lg="6"
                        xl="6"
                        md="6"
                        class="mt-2"
                      >
                        <b-form-group
                          label-for="screen-height"
                          label="Screen Height"
                          description="Size in pixel"
                        >
                          <b-form-input
                            id="screen-height"
                            v-model="device.screen_height"
                            placeholder="Enter Screen Height"
                            variant="primary"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="6"
                        md="6"
                        xl="6"
                        class="mt-2"
                      >
                        <b-form-group
                          label="Screen Width"
                          label-for="screen-width"
                          description="Size in pixel"
                        >
                          <b-form-input
                            id="screen-width"
                            v-model="device.screen_width"
                            placeholder="Enter Screen Width"
                            variant="primary"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="12"
                        xl="12"
                        md="12"
                        class="mt-1"
                      >
                        <h5 class="font-weight-bold">
                          Location & Time
                        </h5>
                      </b-col>
                      <b-col
                        lg="6"
                        xl="6"
                        md="6"
                      >
                        <b-form-group
                          label-for="latitude"
                          label="Latitude"
                        >
                          <b-form-input
                            id="latitude"
                            v-model="device.latitude"
                            placeholder="Enter latitude"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="6"
                        xl="6"
                        md="6"
                      >
                        <b-form-group
                          label-for="longitude"
                          label="Longitude"
                        >
                          <b-form-input
                            id="longitude"
                            v-model="device.longitude"
                            placeholder="Enter longitude"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="12"
                        xl="12"
                        md="12"
                        class="mt-1"
                      >
                        <h5 class="font-weight-bold">
                          Spot Length
                        </h5>
                      </b-col>
                      <b-col
                        lg="6"
                        xl="6"
                        md="6"
                        class="mt-1"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Minimum Spot Length"
                          vid="min-spot-length"
                          rules="required|numeric|min_value:1|max_value:10"
                        >
                          <b-form-group
                            label-for="min-spot-length"
                            label="Minimum Seconds"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="min-spot-length"
                              v-model="device.min_spot_length"
                              placeholder="Enter Minimum Spot length in seconds"
                              type="number"
                              :state="errors.length > 0 ? false:null"
                              vid="min-spot-length"
                              @wheel.prevent
                              @touchmove.prevent
                              @scroll.prevent
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col
                        lg="6"
                        xl="6"
                        md="6"
                        class="mt-1"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Maximum Spot Length"
                          vid="max-spot-length"
                          rules="required|numeric|min_value:11"
                        >
                          <b-form-group
                            label-for="max-spot-length"
                            label="Maximum Seconds"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="max-spot-length"
                              v-model="device.max_spot_length"
                              placeholder="Enter Maximum Spot length in seconds"
                              type="number"
                              variant="primary"
                              :state="errors.length > 0 ? false:null"
                              @wheel.prevent
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col
                        lg="12"
                        xl="12"
                        md="12"
                        class="mt-1"
                      >
                        <h5 class="font-weight-bold">
                          Operating Hours
                        </h5>
                      </b-col>
                      <b-col
                        lg="12"
                        xl="12"
                        md="12"
                      >
                        <HiveStackOperatingHour
                          :operating-hours="device.operating_hours"
                          :impressions="device.impressions"
                          :update-imp="is_change_impression"
                          @operating-hours-updated="handleOperatingHoursUpdated"
                          @hivestack-imp-updated="handleHivestackImpressions"
                          @impressions-updated="handleImpressionsUpdated"
                        />
                      </b-col>
                      <div class="w-100" />
                      <b-col
                        lg="3"
                        xl="3"
                        md="3"
                        class="mt-1"
                      >
                        <b-form-checkbox
                          v-model="device.allow_video"
                          variant="primary"
                          name="allow-video"
                          :value="true"
                          :unchecked-value="false"
                        >
                          Is Video supported ?
                        </b-form-checkbox>
                      </b-col>
                      <b-col
                        lg="3"
                        xl="3"
                        md="3"
                        class="mt-1"
                      >
                        <b-form-checkbox
                          v-model="device.allow_image"
                          variant="primary"
                          name="allow-image"
                          :value="true"
                          :unchecked-value="false"
                        >
                          Is Image allowed ?
                        </b-form-checkbox>
                      </b-col>
                      <b-col
                        lg="3"
                        xl="3"
                        md="3"
                        class="mt-1"
                      >
                        <b-form-checkbox
                          v-model="device.allow_audio"
                          variant="primary"
                          name="allow-audio"
                          :value="true"
                          :unchecked-value="false"
                        >
                          Allowed Audio ?
                        </b-form-checkbox>
                      </b-col>
                      <b-col
                        lg="3"
                        md="3"
                        xl="3"
                        class="mt-1"
                      >
                        <b-form-checkbox
                          v-model="device.active"
                          variant="primary"
                          name="is-active"
                          disabled
                          :value="true"
                          :unchecked-value="false"
                        >
                          Is screen active ?
                        </b-form-checkbox>
                      </b-col>
                      <div class="w-100" />
                      <b-col
                        class="mt-1"
                        cols="11"
                        offset="9"
                        align-self="end"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mr-2"
                          @click="$router.go(-1)"
                        >
                          Cancel
                        </b-button>
                        <b-button
                          filled
                          variant="primary"
                          :disabled="spinner"
                          @click="validationForm()"
                        >
                          <div
                            v-if="spinner"
                            class="spinner"
                          >
                            <b-spinner
                              small
                            />
                            <span class="sr-only">Loading...</span>
                          </div>
                          Submit
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>
              </b-row>
            </validation-observer>
            <BOverlay
              :show="dataLoad"
              no-wrap
            />
          </b-card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Teleport from 'vue2-teleport'
import {
  BRow, BCol, BFormInput, BCard, BCardBody, BFormTextarea, BFormGroup, BFormCheckbox, BButton,
  BSpinner, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { between, numeric } from '@validations'
import Ripple from 'vue-ripple-directive'
import { min_value, max_value, required } from 'vee-validate/dist/rules'
import HiveStackOperatingHour from './HiveStackOperatingHour.vue'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    Teleport,
    vSelect,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BFormTextarea,
    BSpinner,
    HiveStackOperatingHour,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      is_change_impression: false,
      spinner: false,
      required,
      between,
      min_value,
      max_value,
      numeric,
      dataLoad: false,
      checkImpressions: String,
      device: {
        network_id: Number,
        floor_cpm: Number,
        name: String,
        unit_id: String,
        screen_width: Number,
        screen_height: Number,
        is_hivestack_ad_show: Boolean,
        longitude: Number,
        latitude: Number,
        operating_hours: String,
        max_spot_length: Number,
        min_spot_length: Number,
        media_types: Array,
        mediatype_id: Number,
        allow_audio: Boolean,
        allow_image: Boolean,
        allow_video: Boolean,
        description: String,
        impressions: String,
        active: Boolean,
        site: {
          description: String,
          name: String,
          external_id: String,
        },
      },
    }
  },
  computed: {
    adminDevice() {
      return this.$store.getters['devices/getAdminDevice']
    },
  },
  created() {
    this.device = this.$store.getters['devices/getHivestackData']
    this.checkImpressions = this.device.impressions
    this.reloadHivestack(this.$route.params.userId, this.$route.params.deviceId)
    // await this.getSspData(this.$route.params.deviceId, this.$route.params.userId, 'hivestack')
  },
  methods: {
    async validationForm() {
      try {
        const success = await this.$refs.hivestackForm.validate()
        if (success) {
          this.spinner = true
          this.dataLoad = true
          // eslint-disable-next-line
          const device={
            site_name: this.device.site.name,
            site_description: this.device.site.description,
            unit_name: this.device.name,
            unit_description: this.device.description,
            width: this.device.screen_width,
            height: this.device.screen_height,
            max_spot_length: this.device.max_spot_length,
            min_spot_length: this.device.min_spot_length,
            latitude: this.device.latitude,
            longitude: this.device.longitude,
            operating_hours: this.device.operating_hours,
            cpm: this.device.floor_cpm,
            active: this.device.active,
            allow_audio: this.device.allow_audio,
            allow_image: this.device.allow_image,
            allow_video: this.device.allow_video,
            mediatype_id: this.device.mediatype_id,
            is_hivestack_ad_show: this.device.is_hivestack_ad_show,
            impressions: this.device.impressions,
          }
          const mergedObject = {
            ...device,
            deviceId: this.$route.params.deviceId,
            sspType: 'hivestack',
          }
          const { userId } = this.$route.params
          const formData = JSON.stringify(mergedObject)
          await this.$store.dispatch('devices/hivestackConfigure', { payload: formData, userId })
          await this.$router.push({
            name: 'single-device-admin',
            params: {
              deviceId: this.$route.params.deviceId,
              userId: this.$route.params.userId,
              deviceName: this.$route.params.deviceName,
            },
          })
        } else {
          this.spinner = false
          this.dataLoad = false
          this.$swal('Enter valid data')
        }
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        if (typeof message === 'string') {
          this.$swal(message)
        } else {
          this.$swal(message[0].toString())
        }
      } finally {
        this.spinner = false
        this.dataLoad = false
      }
    },
    async reloadHivestack(userId, deviceId) {
      this.dataLoad = true
      const sspType = 'hivestack'
      try {
        const success = await this.$store.dispatch('devices/getDeviceSSpData', { deviceId, sspType, userId })
        if (success) {
          showToast('Hivestack', 'Fetch Hivestack data successfully', 'success')
          this.device = this.$store.getters['devices/getHivestackData']
          this.dataLoad = false
        }
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        if (statusCode === 500) {
          showToast('Internal Network Error', message.toString(), 'danger')
        } else {
          showToast('Authorization Error', message.toString(), 'danger')
        }
        this.dataLoad = false
      }
    },
    // This method will be called when the child component emits the event
    handleOperatingHoursUpdated(updatedOperatingHours) {
      // Capture the updated operatingHours value
      this.device.operating_hours = updatedOperatingHours
    },
    handleImpressionsUpdated(updatedImpressionsUpdated) {
      this.device.impressions = updatedImpressionsUpdated
    },
    handleHivestackImpressions(value) {
      this.is_change_impression = value
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
.check-text{
  padding-top: 8px;
}
.custom-control-primary ::v-deep
  label.custom-control-label {
    padding: 0px !important;
  }
</style>
